import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { FooterAlternate1 } from "../../components/footer"
import SEO from "../../components/seo"

const IndexPage = ({ location }) => (
    <Layout location={location}>
      <SEO title="Challenges" />

      <header className="header header--basic ">
        <div className="header__content">
            <div className="container">
                <div className="row">
                    <div className="header__inner col-sm-6 col-lg-5 col-lg-offset-1">
                        <h1 className="header__title">Challenges</h1>
                        <h3 className="header__caption">When buyers change how they buy, then sellers must also change how they sell.</h3>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div className="landing landing--narrative">
    
    
        <div className="landing__item">
            <div className="landing__svg container">
              
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 715" width="1133" height="715" preserveAspectRatio="xMinYMin"><defs><clipPath id="landingMaskA"><path d="M0 0h1133v307H0z"/></clipPath></defs><g className="hidden-xs" clipPath="url(#landingMaskA)"><path fill="none" stroke="#f9f9fa" strokeWidth="10" strokeMiterlimit="10" d="M566 12h114a50 50 0 0 1 50 43v660"/><path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M566 12h114a50 50 0 0 1 50 43v660"/></g><path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M682 120h451" className="visible-xs"/></svg>
              
            </div>
            <div className="container">
                <div className="row">
                    <div className="landing__columns">
                        <div className="col-xs-12 col-sm-6">
                            <div className="landing__image">
                                <img alt="SalesTribe" src="/media/svg/landing-256x183_man-running.svg" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-4 col-md-offset-1">
                            <Link to="/knowledge-centre/challenges/b2b-sales-getting-harder/" className="landing__copy">
                                <p>Challenge 1</p>
                                <h2 className="landing__title">B2B sales is getting harder.....</h2>
                                <p>63% of sales people are now failing to meet or exceed quota. Are you performing?</p>
                                <button className="btn btn-link">Learn more</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    
        <div className="landing__item is-odd">
            <div className="landing__svg container">
              
              
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 850" width="1133" height="850" preserveAspectRatio="xMinYMin"><defs><clipPath id="landingMaskB"><path d="M0 0h1133v447H0z"/></clipPath></defs><g className="hidden-xs" clipPath="url(#landingMaskB)"><path fill="none" stroke="#f9f9fa" strokeWidth="10" strokeMiterlimit="10" d="M730 0v250a50 50 0 0 1-50 50H240a50 50 0 0 0-50 50v500"/><path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M730 0v250a50 50 0 0 1-50 50H240a50 50 0 0 0-50 50v500"/></g><path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M1133 12H591a25 25 0 0 0-25 25v28" className="visible-xs"/></svg>
              
              
            </div>
            <div className="container">
                <div className="row">
                    <div className="landing__columns">
                        <div className="col-xs-12 col-sm-6 col-sm-push-6">
                            <div className="landing__image">
                                <img alt="SalesTribe" src="/media/svg/landing-256x183_man-playful.svg" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-4 col-sm-pull-5">
                            <Link to="/knowledge-centre/challenges/i-need-to-become-a-specialist/" className="landing__copy">
                                <p>Challenge 2</p>
                                <h2 className="landing__title">I need to become a specialist....</h2>
                                <p>You can no longer add value to the buying process if you have &#39;general&#39; knowledge.</p>
                                <button className="btn btn-link">Learn more</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    
        <div className="landing__item">
            <div className="landing__svg container">
              
              
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 850" width="1133" height="850" preserveAspectRatio="xMinYMin"><defs><clipPath id="landingMaskB"><path d="M0 0h1133v447H0z"/></clipPath><clipPath id="landingMaskBMobile"><path d="M0 0h1133v60H0zM683 180h450v20H683z"/></clipPath></defs><g className="hidden-xs" clipPath="url(#landingMaskB)"><path fill="none" stroke="#f9f9fa" strokeWidth="10" strokeMiterlimit="10" d="M190 0v250a50 50 0 0 0 50 50h440a50 50 0 0 1 50 50v500"/><path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M190 0v250a50 50 0 0 0 50 50h440a50 50 0 0 1 50 50v500"/></g><g className="visible-xs" clipPath="url(#landingMaskBMobile)"><path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M566 0v190h567"/></g></svg>
              
              
            </div>
            <div className="container">
                <div className="row">
                    <div className="landing__columns">
                        <div className="col-xs-12 col-sm-6">
                            <div className="landing__image">
                                <img alt="SalesTribe" src="/media/svg/landing-256x183_woman-laptop.svg" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-4 col-md-offset-1">
                            <Link to="/knowledge-centre/challenges/social-selling-and-my-online-brand/" className="landing__copy">
                                <p>Challenge 3</p>
                                <h2 className="landing__title">I need to build my personal brand......</h2>
                                <p>Your personal brand is what people say about you when you are not in the room.</p>
                                <button className="btn btn-link">Learn more</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    
        <div className="landing__item is-odd">
            <div className="landing__svg container">
              
              
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1133 850" width="1133" height="850" preserveAspectRatio="xMinYMin"><defs><clipPath id="landingMaskB"><path d="M0 0h1133v447H0z"/></clipPath></defs><g className="hidden-xs" clipPath="url(#landingMaskB)"><path fill="none" stroke="#f9f9fa" strokeWidth="10" strokeMiterlimit="10" d="M730 0v250a50 50 0 0 1-50 50H240a50 50 0 0 0-50 50v500"/><path className="green" fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M730 0v250a50 50 0 0 1-50 50H240a50 50 0 0 0-50 50v500"/></g><path fill="none" stroke="#91c848" strokeWidth="10" strokeMiterlimit="10" d="M1133 12H591a25 25 0 0 0-25 25v28" className="visible-xs"/></svg>
              
              
            </div>
            <div className="container">
                <div className="row">
                    <div className="landing__columns">
                        <div className="col-xs-12 col-sm-6 col-sm-push-6">
                            <div className="landing__image">
                                <img alt="SalesTribe" src="/media/svg/landing-256x183_man-pensive.svg" />
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-4 col-sm-pull-5">
                            <Link to="/knowledge-centre/challenges/i-want-make-career-change/" className="landing__copy">
                                <p>Challenge 4</p>
                                <h2 className="landing__title">I want to make a career change....</h2>
                                <p>Your career now depends on how well you understand the new realities of sales, and how well you leverage them to shape your future.</p>
                                <button className="btn btn-link">Learn more</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <svg style={{height: 0, width: 0}} xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id="landingMaskA">
                    <rect x="0" y="0" width="1133" height="307" />
                </clipPath>
                <clipPath id="landingMaskB">
                    <rect x="0" y="0" width="1133" height="447" />
                </clipPath>
                <clipPath id="landingMaskBMobile">
                    <rect x="0" y="0" width="1133" height="60" />
                    <rect x="683" y="180" width="450" height="20" />
                </clipPath>
            </defs>
        </svg>
    </div>
      
      <FooterAlternate1 />

    </Layout>
)

export default IndexPage 